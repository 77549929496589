<template>
  <el-card class="sm" style="height:100%;">
    <p class="text-center">
      <el-button @click="addArea(1, null)" style="width:100%" icon="el-icon-circle-plus-outline">添加根节点 </el-button>
    </p>
    <el-divider> </el-divider>
    <el-tree :data="areaTreeData" node-key="areaId" :props="defaultProps" :default-expanded-keys="defaultExpandedKeys" :expand-on-click-node="false">
      <div class="custom-tree-node" slot-scope="{ node, data }">
        <span class="flex-sub" @click="handleNodeClick(data, node)">{{ node.label }}</span>
        <span>
          <el-button v-if="data.areaType == 0" type="text" size="small" icon="el-icon-circle-plus-outline" @click="addArea(1, data.areaId)"> </el-button>
          <el-button v-if="data.areaType == 1" type="text" size="small" icon="el-icon-circle-plus-outline" @click="addArea(2, data.areaId)"> </el-button>
          <el-button v-if="data.areaType > 1" type="text" size="small" icon="el-icon-rank" @click="drawArea(data)"> </el-button>
          <el-button type="text" size="small" icon="el-icon-edit" @click="editArea(data)"></el-button>
          <el-button type="text" size="small" icon="el-icon-delete" @click="deleteArea(data)"> </el-button>
        </span>
      </div>
    </el-tree>
  </el-card>
</template>

<script>
import { mapState } from 'vuex'
import areaAddoredit from './_addoredit'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  props: {
    monitorData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      areaTreeData: null,
      defaultProps: {
        children: 'children',
        label: 'areaName'
      },
      defaultExpandedKeys: []
    }
  },
  mounted() {
    this.getAreaTree()
  },
  methods: {
    async getAreaTree() {
      this.areaTreeData = await this.$lgh.get('api/enterprise/easset/area/tree', ['enterpriseCode'])
    },
    async addArea(tag, areaId) {
      alert(this.areaTreeData)
      var r = await this.$lgh.openDialog({
        ins: areaAddoredit,
        title: '新增节点',
        data: {
          typeTag: tag,
          areaTreeData: this.areaTreeData,
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            areaType: tag,
            parentId: areaId,
            imgUrl: null
          }
        }
      })
      this.dealAddEditResult(r)
    },
    async editArea(data) {
      var r = await this.$lgh.openDialog({
        ins: areaAddoredit,
        title: '修改节点',
        data: {
          areaTreeData: this.areaTreeData,
          dataInfo: Object.assign({}, data)
        }
      })
      this.dealAddEditResult(r)

      // this.defaultExpandedKeys = [data.areaId]
    },
    dealAddEditResult(r) {
      this.defaultExpandedKeys = [r.areaId]

      if (r.areaType === 1 && !r.lnglats) {
        var bounds = this.$parent.$refs.map.$refs.map.getImageLayerSetting(r.imgWidth, r.imgHeight)
        r.lnglats = bounds[0].lng + ',' + bounds[0].lat + ';' + bounds[1].lng + ',' + bounds[1].lat
        this.updateAreaData(r)
      } else {
        this.getAreaTree()
      }
    },
    updateAreaData(data) {
      this.$lgh.post('api/enterprise/easset/area/updatedata', data).then(res => {
        this.getAreaTree()
      })
    },
    deleteArea(data) {
      this.$lgh.msgConfirm('确定删除[' + data.areaName + ']?', () => {
        this.$lgh.get('api/enterprise/easset/area/delete', { areaId: data.areaId }).then(res => {
          this.defaultExpandedKeys = data.parentId ? [data.parentId] : []
          this.getAreaTree()
        })
      })
    },
    handleNodeClick(data, node) {
      if (data.areaType === 0) {
        node.expanded = true
      } else if (data.areaType === 1) {
        node.expanded = true
        this.showAreaLayerToMap(data.areaId)
      }
    },
    showAreaLayerToMap(areaId, cb) {
      this.$lgh.get('api/enterprise/easset/area/detail', { areaId: areaId }).then(res => {
        cb ? cb(res) : this.setGroupToMap(res)
      })
    },
    setGroupToMap(group) {
      this.$parent.$refs.map.setGroupToMap(group)
    },
    drawArea(data) {
      if (data.areaType === 2) {
        this.$parent.$refs.map.$refs.map.drawPolygon()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
